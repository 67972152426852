import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "fin"
    }}>{`Fin`}</h1>
    <p>{`Those are the basic ideas behind how we do React at Tia. You'll pick up all sorts of details as we work together.`}</p>
    <p>{`So long and thanks for all the fish.`}</p>
    <p><video parentName="p" {...{
        "style": {
          "margin": "auto auto",
          "display": "block",
          "maxWidth": "80%"
        },
        "autoPlay": true,
        "loop": true,
        "muted": true,
        "playsInline": true,
        "loading": "lazy"
      }}>{`
            `}<source parentName="video" {...{
          "src": "https://media2.giphy.com/media/U3DpK1xo0FZTPrbjGP/giphy-loop.mp4?cid=4ac046a2rs3hys0h3nlqgvoqpwqhj4b9mjd13nbr7x5twtzm&rid=giphy-loop.mp4",
          "type": "video/mp4"
        }}></source>{`
        `}</video></p>
    <p>{`This page is going to stay up, you can refer to it any time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      